import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import * as styles from "../styles/resume.module.css"

const portoLinks = [
  "https://github.com/user-attachments/assets/aae6a360-3017-49f1-aaf8-eb1fb6e13b4e",
  "https://github.com/user-attachments/assets/60c80f66-6ef3-41d2-b5ee-d3ffb79c7f5e",
  "https://github.com/user-attachments/assets/739a929f-90de-4557-849f-987bbfccb027",
  "https://github.com/user-attachments/assets/94a7d892-9941-488c-bebf-7917be42f95c",
  "https://github.com/user-attachments/assets/0ba06a01-ff4c-401c-b192-55c02a021506",
  "https://github.com/user-attachments/assets/b83ec155-6477-4144-927e-0447978b851d",
  "https://github.com/user-attachments/assets/04101d10-d999-4e26-9b63-65316f2df5bb",
  "https://github.com/user-attachments/assets/b02798df-4ef7-470e-bda9-44d157ac58d3",
  "https://github.com/user-attachments/assets/816cb2db-f7e2-498c-b89b-1f4240f13376",
  "https://github.com/user-attachments/assets/0cd695d9-5272-4853-a9b9-ae5632b23b7a",
  "https://github.com/user-attachments/assets/51be5ec8-1191-43a9-9d91-e3a0aba00f79",
  "https://github.com/user-attachments/assets/7e8971ba-633c-4a54-8a30-ac5e2d34881b",
  "https://github.com/user-attachments/assets/1ec62eda-71cb-4306-b8db-9d9ae517fc5d",
  "https://github.com/user-attachments/assets/6cb03cb7-980f-4344-81d8-c05d956d105a",
  "https://github.com/user-attachments/assets/a3014ec6-9726-4beb-9b92-d1b831ed8727",
  "https://github.com/user-attachments/assets/10d43dd8-071d-44ec-ab82-2e6639d59fbd",
  "https://github.com/user-attachments/assets/1d9e7812-b75d-435f-af76-3e09fa32af8c",
]

const Resume = () => {
  return (
    <Layout>
      <Seo title="Resume" />

      <div
        className={styles.tophr + ` fixed-top`}
        style={{ zIndex: "1" }}
      ></div>

      <div
        className={styles.resumeWrapper + ` parallax1`}
        style={{ minHeight: "85vh" }}
      >
        <div className="container px-2 px-md-5 py-5">
          <h1>Portfolio</h1>

          <div
            className="d-flex align-items-center"
            style={{ gap: "32px", marginBottom: "20px" }}
          >
            <p className="mb-0 d-flex align-items-center">
              Link :&nbsp;
              <a
                href="https://t.ly/0X9FF"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
              >
                https://t.ly/0X9FF
              </a>
            </p>
            <p className="mb-0 d-flex align-items-center">
              Email :&nbsp;
              <a
                href="mailto:aurellyagho25@gmail.com"
                className="text-decoration-none"
              >
                aurellyagho25@gmail.com
              </a>
            </p>
          </div>

          <div className="row">
            {portoLinks.map((item, itemIndex) => (
              <div
                key={itemIndex}
                className="col-12 col-md-6 mb-4"
                style={{ maxWidth: "600px" }}
              >
                <img
                  src={item || ""}
                  alt={`porto-page-${itemIndex}`}
                  className="img-fluid"
                  style={{ objectFit: "cover", height: "auto" }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.bottomhr}></div>
    </Layout>
  )
}

export default Resume
